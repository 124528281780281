@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

:root {
  --fs-xl: clamp(0.7rem, 1vw + 1rem, 2rem);
  --fs-md: clamp(0.4rem, .2vw + .7rem, 1rem);
}


h2 {
  font-weight: bold;
  font-size: inherit; /* or specify a specific value like '16px' */
}

h3 {
  font-weight: normal;
  font-size: inherit; /* or specify a specific value like '16px' */
}

h4 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: inherit; /* or specify a specific value like '16px' */
}


/* @media (min-width: 20em) {
  :root{
    --fs-xl: 1.3rem;
    --fs-md: .3rem;
  }
} */


/*----masonry-----*/
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.image {
  display:block;
  width: 100%
}
/*----masonry-----*/



.container {
  display: flex;
  justify-content: space-between;
}



.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  
  padding: 6rem 0 3rem 1rem;
}

.works {
  text-indent: 0rem;
}

.paragraph-header{
  text-indent: 0rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: var(--fs-xl);
  padding-bottom: .5rem;
}

.paragraph {
  font-family: "Libre Baskerville", serif;
  font-size: var(--fs-md);
  margin-top: 6.55rem;
  
  padding-right: 3rem;

  text-indent: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

.title {
  font-family: "Libre Baskerville", serif;
  font-size: var(--fs-xl);
}

.title a {
  text-decoration: none;
  color: inherit;
}

.indentme{
  margin-top: 0.2rem;
  padding-left: 0.9rem;
  /* text-indent: 1rem; */
  
}

.small-text {
  margin-top: 1rem;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: var(--fs-md);
}

.h1-text {
  margin-top: 0rem;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: var(--fs-md);
}

.small-text a {
  text-decoration: none;
  color: inherit;
}

.small-text ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.small-text li {
  padding-bottom: 0.2rem;
  display: block;
}

a:hover {
  font-weight: bold;
}

h3:hover {
  font-weight: bold;
}



.margin-firstline {
  margin-top: 0.5rem;
}
.margin-aboutme {
  margin-top: 0.5rem;
  color:rgb(0, 0, 0); 
  margin-bottom: .2rem;
  
}

.flexbox-1 {
  flex: 0 0 30%;
  padding-left: 1rem;
  
  align-items: center;
  justify-content: center;

}

.flexbox-2 {
  /* margin-left: 2rem; */
  flex: 0 0 70%;
  padding: 3rem 1rem 3rem 2rem;

}

hr {
  margin-top: 0rem;
  margin-right: 30rem ;
}

.ig-flex {
  display: block;
  
}

p {
  font-family: 'Roboto', sans-serif;
  width: 60%;
}





